import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { ALLOWED_EMAIL_DOMAINS } from '@constants/domains.constants';

export const phoneLengthValidator = (minLength: number, maxLength: number): ValidatorFn => {
  return (control: AbstractControl): ValidationErrors | null => {
    const phoneNumber = control.value ? control.value.replace(/\s/g, '') : '';
    const length = phoneNumber.length;

    if (length < minLength) {
      return { minlength: true };
    }

    if (length > maxLength) {
      return { maxlength: true };
    }

    return null;
  };
}

export const domainValidator = (control: AbstractControl): ValidationErrors | null  => {
    const email: string = control.value;
    const domain = email?.substring(email?.lastIndexOf('.') + 1);
    if (ALLOWED_EMAIL_DOMAINS.includes(domain?.toUpperCase())) {
      return null;
    } else {
      return { invalidDomain: true };
    }
};
