<div class="picker">
    <div
        *ngFor="let option of _pickerData()"
        class="option"
        role="option"
        [class.active]="isSelected(option.value)"
        [attr.aria-selected]="isSelected(option.value)"
        tabindex="0"
        (keydown)="onKeyDown($event, option.value)"
        (click)="toggleOption(option.value)"
    >
        {{ option.label }}
    </div>
</div>
