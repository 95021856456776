import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Options } from '@angular-slider/ngx-slider';
import { DecimalPipe } from '@angular/common';

@Component({
    selector: 'app-slider',
    templateUrl: './slider.component.html',
    styleUrls: ['./slider.component.scss'],
    providers: [DecimalPipe],
})
export class SliderComponent {
    isSliderPressed = false;

    @Input() value: number = 0;
    @Input() formattedSliderValue: string;
    @Input() options: Options = {
        floor: 0,
        ceil: 100,
        step: 50,
    };
    @Output() valueChanged = new EventEmitter<number>();

    constructor(private decimalPipe: DecimalPipe) {
        this.formattedSliderValue = this.formatNumber(this.value.toString());
    }

    decreaseValue(): void {
        if (this.options.step && this.options.floor) {
            this.value = Math.max(this.options.floor, this.value - this.options.step);
            this.valueChanged.emit(this.value);
        }
    }

    increaseValue(): void {
        if (this.options.step && this.options.ceil) {
            this.value = Math.min(this.options.ceil, this.value + this.options.step);
            this.valueChanged.emit(this.value);
        }
    }

    formatNumber(value: string): string {
        return this.decimalPipe.transform(value, '1.0-0') || '';
    }

    onUserChangeEnd(value: number): void {
        this.formattedSliderValue = this.formatNumber(value.toString());
        this.value = value;
        this.valueChanged.emit(value);
        this.isSliderPressed = false;
    }

    onUserChangeStart(): void {
        this.isSliderPressed = true;
    }
}
