import { Routes } from '@angular/router';
import { YourCleanComponent } from '@pages/your-clean/your-clean.component';
import { QuoteComponent } from '@pages/quote/quote.component';
import { SummaryComponent } from '@pages/summary/summary.component';
import { SpecialAttentionComponent } from '@pages/special-attention/special-attention.component';
import { ManagerRequestComponent } from '@pages/manager-request/manager-request.component';
import { BookingCompletedComponent } from '@pages/booking-completed/booking-completed.component';
import { PageNotFoundComponent } from '@pages/page-not-found/page-not-found.component';
import { PAGES_URLS, PAGES } from '@constants/pages-config.constants';

const routes: Routes = [
    { path: '', redirectTo: PAGES_URLS[PAGES.YOUR_CLEAN], pathMatch: 'full'},
    { path: PAGES_URLS[PAGES.YOUR_CLEAN], component: YourCleanComponent },
    { path: PAGES_URLS[PAGES.QUOTE], component: QuoteComponent },
    { path: PAGES_URLS[PAGES.SUMMARY], component: SummaryComponent },
    { path: PAGES_URLS[PAGES.BOOKING_COMPLETED], component: BookingCompletedComponent },
    { path: PAGES_URLS[PAGES.SPECIAL_ATTENTION], component: SpecialAttentionComponent },
    { path: PAGES_URLS[PAGES.MANAGER_REQUEST], component: ManagerRequestComponent },
    { path: PAGES_URLS[PAGES.NOT_FOUND], component: PageNotFoundComponent },
    { path: '**', component: PageNotFoundComponent }
];


export default routes;
