import { Component, Input, Output, EventEmitter, Renderer2 } from '@angular/core';

@Component({
    selector: 'app-detailed-dialog',
    templateUrl: './detailed-dialog.component.html',
    styleUrls: ['./detailed-dialog.component.scss'],
})
export class DetailedDialogComponent {
    @Input() isOpen = false;
    @Input() data: any;
    @Output() closeDialog = new EventEmitter<any>();
    isClosing = false;
    startY: number = 0;
    endY: number = 0;
    isMobile = window.innerWidth <= 576;

    constructor(private renderer: Renderer2) {}

    onKeyDownClose(event: KeyboardEvent) {
        const target = event.target as HTMLElement;
        if (event.key === 'Enter') {
            event.preventDefault();
            this.onClose();
        }
    }

    onClose(): void {
        this.enableBodyScroll();
        this.isClosing = true;
        setTimeout(() => {
            this.isOpen = false;
            this.isClosing = false;
            this.closeDialog.emit({ result: null });
        }, 300);
    }

    onTouchStart(event: TouchEvent): void {
        this.startY = event.touches[0].clientY;
        this.disableBodyScroll();
    }

    onTouchMove(event: TouchEvent): void {
        this.endY = event.touches[0].clientY;
    }

    onTouchEnd(): void {
        const swipeDistance = this.endY - this.startY;
        const swipeThreshold = 40;
        if (swipeDistance > swipeThreshold) {
            this.onClose();
        }
    }

    toggleDetailedMode() {
        this.enableBodyScroll();
        this.isClosing = true;
        setTimeout(() => {
            this.isOpen = false;
            this.isClosing = false;
            this.data.isDetailedMode = !this.data.isDetailedMode;
            this.closeDialog.emit({ result: this.data.isDetailedMode });
        }, 300);
    }

    disableBodyScroll(): void {
        this.renderer.setStyle(document.body, 'overflow', 'hidden');
    }

    enableBodyScroll(): void {
        this.renderer.removeStyle(document.body, 'overflow');
    }
}
