import {
    Component,
    Input,
    Output,
    OnChanges,
    EventEmitter,
    SimpleChanges,
} from '@angular/core';

@Component({
    selector: 'app-stepper-input',
    templateUrl: './stepper-input.component.html',
    styleUrls: ['./stepper-input.component.scss'],
})
export class StepperInputComponent implements OnChanges {
    @Input() initialValue = 0;
    @Input() step = 0;
    @Input() min = 0;
    @Input() max!: number;
    renderedValue = '';
    @Output() valueChange = new EventEmitter<number>();

    value: number = this.initialValue;

    ngOnChanges(changes: SimpleChanges): void {
        if (changes['initialValue']) {
            this.value = changes['initialValue'].currentValue;
        }
    }

    increment(): void {
        if (this.max && this.value + this.step <= this.max) {
            this.value += this.step;
            this.emitValueChange();
        }
    }

    decrement(): void {
        if (this.value - this.step >= this.min) {
            this.value -= this.step;
            this.emitValueChange();
        }
    }

    onInputChange(event: Event): void {
        const inputValue = (event.target as HTMLInputElement).valueAsNumber;
        this.value = inputValue;
    }

    onInputBlur(event: Event): void {
        const inputValue = (event.target as HTMLInputElement).valueAsNumber;
        if (inputValue < this.min) {
            this.value = this.min;
        } else if (inputValue > this.max) {
            this.value = this.max;
        } else {
            this.value = inputValue;
        }
        this.emitValueChange();
        (event.target as HTMLInputElement).value = this.value.toString();
    }

    private emitValueChange(): void {
        this.valueChange.emit(this.value);
    }
}
