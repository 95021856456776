import { Component, OnInit, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { Subscription } from 'rxjs';
import { BaseComponent } from '@components/base-component';
import { StoreDataService } from '@common/services/store-data.service';
import { PricingQuoteService } from '@common/services/pricing-quote.service';
import { DialogService } from '@common/services/dialog.service';
import { Profile } from '@common/models/profile.model';
import { FrequencyType } from '@common/models/frequency-type.model';
import { BUTTON_BACK, BUTTON_NEXT } from '@constants/pages-config.constants';
import {
    Room,
    RoomObj,
    Appliance,
    ApplianceObj,
    SelectedAppliance,
} from '@common/models/rooms.model';
import { Price } from '@common/models/price.model';
import { PickerData } from '@common/models/picker-option.model';
import { CLEANING_FREQUENCY, OCCASIONS, CLEAN_TYPES } from '@constants/quote-properties.constants';
import {
    ALL_ROOMS_OBJ,
    APPLIANCES_OBJ,
    RoomName,
    ROOM_NAMES,
} from '@constants/room-data.constants';
import { CHECK_PROFILE_STATE_INT_VALUE } from '@constants/common.constants';

@Component({
    selector: 'app-quote',
    templateUrl: './quote.component.html',
    styleUrls: ['./quote.component.scss'],
})
export class QuoteComponent extends BaseComponent implements OnInit, OnDestroy {
    override progressBarStatus = {
        progress: 2,
        completedStep: 1,
    };
    override navigationSubscription!: Subscription;
    override navigationRoutes = {
        nextRoute: this.pagesUrls[this.pages.SUMMARY],
        backRoute: this.pagesUrls[this.pages.YOUR_CLEAN],
    };
    override isNextButtonDisabled = true;
    override isNextButtonActive = false;
    nextButtonText = 'Continue';

    subscriptionType: string | null = null;
    profileSubscription!: Subscription;

    cleanTypes = CLEAN_TYPES;
    roomNames = ROOM_NAMES;

    frequencyTypesMap: Array<FrequencyType> = [];
    selectedFrequency: FrequencyType | null = null;

    cleaningFrequency = CLEANING_FREQUENCY;
    selectedFrequencyValue: string = this.cleaningFrequency.WEEKLY;
    frequencyTouched = false;
    frequencyData: Array<PickerData> = [
        { label: 'Weekly', value: this.cleaningFrequency.WEEKLY },
        { label: 'Every other week', value: this.cleaningFrequency.BI_WEEKLY },
        { label: 'Every four weeks', value: this.cleaningFrequency.MONTHLY },
        { label: 'One-time', value: this.cleaningFrequency.ONE_TIME },
    ];

    occasions = OCCASIONS;
    occasionData: Array<PickerData> = [
        { label: 'Moving out', value: this.occasions.MOVING_OUT },
        { label: 'Moving in', value: this.occasions.MOVING_IN },
        { label: 'Special occasion', value: this.occasions.SPECIAL },
        { label: 'Other', value: this.occasions.OTHER },
    ];
    selectedOccasionValue!: string;

    isOneTimeClean = false;
    oneTimeStandardClean: number | null = null;

    otherRooms: Room[] = [];
    otherRoomsObj: RoomObj[] = [];
    rooms: Room[] = [];
    bedroomsObj: RoomObj[] = [];
    fullBathroomsObj: RoomObj[] = [];
    halfBathroomsObj: RoomObj[] = [];

    locationPhone: string | null = null;

    get allRooms() {
        return [
            ...this.bedroomsObj,
            ...this.fullBathroomsObj,
            ...this.halfBathroomsObj,
            ...this.otherRoomsObj,
        ];
    }

    isAnyRoomSelected = false;

    isDetailedMode = false;

    appliancesObj!: ApplianceObj[];
    selectedAppliances!: SelectedAppliance[];

    BEDROOMS_INITIAL_NUMBER = 1;
    BATHROOMS_INITIAL_NUMBER = 1;
    HALF_BATHROOMS_INITIAL_NUMBER = 1;
    MAX_ADD_ROOMS_NUMBER = 10;

    MAX_DETAILED_ROOMS = 4;
    detailedRoomsCounter: number = 0;

    profileState!: Profile;
    checkProfileStateInterval!: ReturnType<typeof setInterval>;

    price!: Price;
    firstTimePrice!: number | null;
    recurringPrice!: number | null;

    isContactDialogOpen = false;
    contactDialogData!: {
        phoneNumber: string;
    };
    isDetailedDialogOpen = false;
    detailedDialogData!: {
        isDetailedMode: boolean;
    };

    dialogSubscription!: Subscription;
    detailedDialogSubscription!: Subscription;
    contactDialogSubscription!: Subscription;
    dialogDetailedService!: DialogService;
    dialogContactService!: DialogService;

    currentKeyDownHandler!: (event: KeyboardEvent) => void;

    constructor(
        private storeDataService: StoreDataService,
        private pricingQuoteService: PricingQuoteService,
        private cd: ChangeDetectorRef
    ) {
        super();

        this.checkProfileStateInterval = setInterval(() => {
            this.storeDataService.updateProfileData(this.profileState);
        }, CHECK_PROFILE_STATE_INT_VALUE);

        this.dialogDetailedService = new DialogService();
        this.dialogContactService = new DialogService();
        this.dialogDetailedService.dialogState$.subscribe((state) => {
            this.isDetailedDialogOpen = state.isOpen;
            this.detailedDialogData = state.data;
        });
        this.dialogContactService.dialogState$.subscribe((state) => {
            this.isContactDialogOpen = state.isOpen;
            this.contactDialogData = state.data;
        });
    }

    override ngOnInit(): void {
        super.ngOnInit();
        super.setBrowserBackRoute(this.navigationRoutes.backRoute);
        this.navigationService.setNextButtonActive(this.isNextButtonActive);
        const nextButtonSubscription = this.navigationService
            .getNavigationButtonClicked()
            .subscribe((res) => {
                if (this.isNavigationButtonClicked(res.button) && !res.allowNavigation) {
                    this.storeCurrentData(res.button);
                }
            });

        this.profileSubscription = this.storeDataService
            .profileObserver()
            .subscribe((profile: Profile) => {
                if (profile.profileId && !profile.skipUpdate) this.setupDefaultOptions(profile);
                delete profile.skipUpdate;
            });
        this.addSubscription(this.profileSubscription);
        this.addSubscription(nextButtonSubscription);
    }

    setupDefaultOptions(profile: Profile) {
        this.profileState = JSON.parse(JSON.stringify(profile));
        if (profile?.locationCode) {
            this.getSubscriptionType(profile.locationCode);
        }

        if (profile.otherRooms?.length) {
            this.getOtherRooms(profile);
        }

        if (profile.rooms?.length) {
            this.getRooms(profile);
        } else {
            this.getRoomTypes();
            this.getAppliances(profile);
        }

        if (profile.locationPhone) {
            this.locationPhone = this.phoneFormatter(profile.locationPhone);
        }

        if (profile.cleaningFrequency) {
            this.selectedFrequencyValue = profile.cleaningFrequency;
        } else {
            this.profileState.cleaningFrequency = this.selectedFrequencyValue;
        }
        if (profile.cleaningOccasion) {
            this.selectedOccasionValue = profile.cleaningOccasion as string;
        }

        this.countDetailedRooms();
        this.handleNavigation();
        this.checkIsDetailedMode();
        this.navigationService.setNextButtonLabel(this.nextButtonText);
    }

    phoneFormatter(phone: string): string {
        return `(${phone.slice(0, 3)}) ${phone.slice(3, 6)}-${phone.slice(6, 10)}`;
    }

    getSubscriptionType(locationCode: number) {
        this.pricingQuoteService.getQuoteRate(locationCode).subscribe((res) => {
            this.subscriptionType = res.subscriptionType;
        });
    }

    getAppliances(profile: Profile) {
        this.pricingQuoteService
            .getAppliances(profile.locationCode)
            .subscribe((appliances: Appliance[]) => {
                this.mapAppliances(appliances);
                this.getPricingQuote();
            });
    }

    getOtherRooms(profile: Profile) {
        this.otherRooms = profile.otherRooms?.map((room) => ({ ...room })) as Room[];
        this.otherRoomsObj = this.mapOtherRooms();
    }

    getRooms(profile: Profile) {
        this.rooms = profile.rooms?.map((room) => JSON.parse(JSON.stringify(room))) as Room[];
        this.bedroomsObj = this.mapRooms(this.roomNames.BEDROOM);
        this.fullBathroomsObj = this.mapRooms(this.roomNames.BATHROOM);
        this.halfBathroomsObj = this.mapRooms(this.roomNames.HALF_BATH);
        this.pricingQuoteService
            .getRoomTypes(this.storeDataService.locationCode, {
                levels: this.storeDataService.profileData.levels,
                numberOfBedrooms: this.BEDROOMS_INITIAL_NUMBER,
                numberOfBathrooms: this.BATHROOMS_INITIAL_NUMBER,
                numberOfHalfbathrooms: this.HALF_BATHROOMS_INITIAL_NUMBER,
            })
            .subscribe((rooms: Room[]) => {
                let updatedHallwaysAndStairs = rooms.filter(
                    (room) =>
                        room.roomName.toLowerCase() === this.roomNames.HALLWAY ||
                        room.roomName.toLowerCase() === this.roomNames.STAIRS
                );
                if (this.isDetailedMode) {
                    updatedHallwaysAndStairs.forEach((room) => (room.isDetailed = true));
                }
                const profileRooms = profile.rooms?.map((room) => JSON.parse(JSON.stringify(room))) as Room[];
                const nonHallwayAndStairsRooms = profileRooms.filter(
                    (room) =>
                        room.roomName.toLowerCase() !== this.roomNames.HALLWAY &&
                        room.roomName.toLowerCase() !== this.roomNames.STAIRS
                );
                this.rooms = [...nonHallwayAndStairsRooms, ...updatedHallwaysAndStairs];
                this.profileState.rooms = this.rooms;
                this.storeDataService
                    .storeCurrentData({
                        rooms: this.rooms,
                    }, true, true)
                    .subscribe();

                this.getAppliances(profile);
                this.checkIsAnyRoomSelected();
                this.checkIsDetailedMode();
            });
    }

    openDetailedDialog(): void {
        const data = {
            isDetailedMode: this.isDetailedMode,
        };
        this.googleAnalyticsService.event('sparkle_popup_shown', 'sparkle', 'extra sparkle popup');
        setTimeout(() => {
            const dialogButtonFocused = document.getElementById('dialogButtonFocused');
            dialogButtonFocused?.focus();
            this.trapFocusInDialog('detailedDialog');
        }, 0);
        this.detailedDialogSubscription = this.dialogDetailedService
            .openDialog(data)
            .subscribe((result) => {
                this.cd.detectChanges();
                if (result.result !== null) {
                    this.toggleDetailedMode(result.result);
                }
            });
    }

    refreshTrapFocusInDialog(id: string) {
        document.removeEventListener('keydown', this.currentKeyDownHandler);
        setTimeout(() => {
            this.trapFocusInDialog(id);
        }, 0);
    }

    trapFocusInDialog(id: string) {
        const dialog = document.getElementById(id);
        const focusableElements = Array.from(
            (dialog as HTMLElement)?.querySelectorAll<HTMLElement>(
                'button:not([disabled]), input, label[tabindex="0"], a'
            )
        );
        const firstFocusableElement = focusableElements?.[0];
        const lastFocusableElement = focusableElements?.[focusableElements.length - 1];
        let handleFocus = function (event: KeyboardEvent) {
            if (event.key === 'Tab') {
                if (event.shiftKey) {
                    if (document.activeElement === firstFocusableElement) {
                        lastFocusableElement.focus();
                        event.preventDefault();
                    }
                } else {
                    if (document.activeElement === lastFocusableElement) {
                        firstFocusableElement.focus();
                        event.preventDefault();
                    }
                }
            }
        }
        this.currentKeyDownHandler = handleFocus;
        document.addEventListener('keydown', this.currentKeyDownHandler);
    }

    onDetailedDialogClosed(event: Event): void {
        this.dialogDetailedService.closeDialog(event);
        document.removeEventListener('keydown', this.currentKeyDownHandler);
        document.getElementById('detailedModeBtn')?.focus();
        if (this.detailedDialogSubscription) {
            this.detailedDialogSubscription.unsubscribe();
        }
    }

    openContactDialog(): void {
        const data = {
            phoneNumber: this.storeDataService.profileData.phone,
        };
        setTimeout(() => {
            const dialogButtonFocused = document.getElementById('dialogButtonFocused');
            dialogButtonFocused?.focus();
            this.trapFocusInDialog('contactDialog');
        }, 0);
        this.contactDialogSubscription = this.dialogContactService.openDialog(data).subscribe((result) => {
            if (result.result?.contactPreference && result.result?.phone) {
                this.profileState.contactPreference = [];
                this.profileState.contactPreference.push(result.result.contactPreference);
                this.profileState.phone = result.result.phone;
                this.navigationRoutes.nextRoute = this.pagesUrls[this.pages.MANAGER_REQUEST];
                this.googleAnalyticsService.event(
                    'contact_me_btn_clicked',
                    'contact_me',
                    'Contact Me'
                );
                if (!this.frequencyTouched) {
                    this.googleAnalyticsService.event(
                        'frequency_changed',
                        'frequency',
                        this.selectedFrequencyValue as string
                    );
                }
                this.storeDataService
                    .storeCurrentData({
                        ...this.profileState,
                        url: this.pagesUrls[this.pages.MANAGER_REQUEST],
                        subscriptionType: this.subscriptionType,
                    })
                    .subscribe(() => {
                        this.storeDataService.sendOrder('CustomizePrice').subscribe(() => {
                            this.navigationService.setNavigationButtonClicked({
                                button: BUTTON_NEXT,
                                allowNavigation: true,
                            });
                        });
                    });
            }
        });
    }

    onContactDialogClosed(event: Event): void {
        this.dialogContactService.closeDialog(event);
        document.removeEventListener('keydown', this.currentKeyDownHandler);
        this.googleAnalyticsService.event(
            'contact_me_popup_closed',
            'contact_me',
            'Close Contact me popup'
        );
        document.getElementById('contactMeBtn')?.focus();
        if (this.contactDialogSubscription) {
            this.contactDialogSubscription.unsubscribe();
        }
    }

    getRoomTypes() {
        this.pricingQuoteService
            .getRoomTypes(this.storeDataService.locationCode, {
                levels: this.storeDataService.profileData.levels,
                numberOfBedrooms: this.BEDROOMS_INITIAL_NUMBER,
                numberOfBathrooms: this.BATHROOMS_INITIAL_NUMBER,
                numberOfHalfbathrooms: this.HALF_BATHROOMS_INITIAL_NUMBER,
            })
            .subscribe((rooms: Room[]) => {
                this.profileState.rooms = rooms;
                this.storeDataService
                    .storeCurrentData({
                        rooms: rooms,
                    })
                    .subscribe(() => {
                        this.rooms = (rooms as Room[]).map((room) => ({ ...room }));
                        this.bedroomsObj = this.mapRooms(this.roomNames.BEDROOM);
                        this.fullBathroomsObj = this.mapRooms(this.roomNames.BATHROOM);
                        this.halfBathroomsObj = this.mapRooms(this.roomNames.HALF_BATH);
                        this.checkIsAnyRoomSelected();
                        this.checkIsDetailedMode();
                        this.getPricingQuote();
                    });
            });
    }

    checkIsDetailedMode() {
        this.isDetailedMode = this.allRooms?.every((room) => room.isDetailed);
    }

    countDetailedRooms() {
        this.detailedRoomsCounter = this.allRooms?.filter((room) => room.isDetailed).length;
    }

    mapOtherRooms() {
        return this.otherRooms.map((otherRoom) => ({
            ...ALL_ROOMS_OBJ[otherRoom.roomName],
            isSelected: otherRoom.isSelected,
            isDetailed: otherRoom.isDetailed,
            id: otherRoom.id,
        }));
    }

    mapRooms(roomName: RoomName) {
        return this.rooms
            .filter((room) => room.roomName.toLowerCase() === roomName)
            .map((room) => {
                return {
                    ...ALL_ROOMS_OBJ[room.roomName],
                    isDetailed: room.isDetailed,
                    isSelected: room.isSelected,
                    id: room.id,
                };
            });
    }

    isNameChanged(index: number) {
        return (
            index === this.allRooms.length - 1 ||
            this.allRooms[index].roomName !== this.allRooms[index + 1].roomName
        );
    }

    mapAppliances(appliances: Appliance[]) {
        const profileAppliances = this.storeDataService.profileData.appliances?.map(
            (appliance) => appliance.type
        );
        this.appliancesObj = appliances.map((appliance) => ({
            ...APPLIANCES_OBJ[appliance.type],
            type: appliance.type,
            price: Math.ceil(appliance.price),
            isSelected: !!profileAppliances?.includes(appliance.type),
        }));
    }

    checkIsAnyRoomSelected() {
        this.isAnyRoomSelected =
            this.rooms.some(
                (room) =>
                    room.isSelected &&
                    room.roomName.toLowerCase() !== this.roomNames.HALLWAY &&
                    room.roomName.toLowerCase() !== this.roomNames.STAIRS &&
                    room.roomName.toLowerCase() !== this.roomNames.FOYER
            ) || this.otherRooms.some((room) => room.isSelected);
        this.handleNavigation();
    }

    toggleDetailedMode(isDetailed: boolean) {
        const allRooms = [
            ...this.rooms,
            ...this.otherRooms,
            ...this.otherRoomsObj,
            ...this.bedroomsObj,
            ...this.fullBathroomsObj,
            ...this.halfBathroomsObj,
        ];
        allRooms.forEach((room) => (room.isDetailed = isDetailed));
        this.isDetailedMode = isDetailed;
        if (isDetailed) {
            this.googleAnalyticsService.event(
                'extra_sparkle_mode_on',
                'sparkle',
                'confirm extra sparkle'
            );
        } else {
            this.googleAnalyticsService.event(
                'extra_sparkle_mode_off',
                'sparkle',
                'cancel extra sparkle'
            );
        }

        this.countDetailedRooms();
        this.setPriceByFrequency();
        this.profileState.rooms = this.rooms;
        this.profileState.otherRooms = this.otherRooms;
    }

    isAllAppliancesSelected(): boolean {
        return this.appliancesObj?.every((appliance: ApplianceObj) => appliance.isSelected);
    }

    toggleAllAppliancesSelection(event: Event) {
        const target = event.target as HTMLInputElement;
        const isChecked = target.getAttribute('aria-checked')
            ? target.getAttribute('aria-checked') === 'false'
            : target.checked;
        this.appliancesObj.forEach((appliance: ApplianceObj) => (appliance.isSelected = isChecked));
        this.getPricingQuote();
        this.profileState.appliances = this.selectedAppliances;
        const applianceNames = this.appliancesObj.map((item) => item.type).join(', ');
        this.googleAnalyticsService.event(
            isChecked ? 'all_appliances_added' : 'all_appliances_removed',
            'appliances',
            applianceNames
        );
    }

    onKeyDownAppliance(event: KeyboardEvent, appliance: ApplianceObj) {
        if (event.key === 'Enter') {
            event.preventDefault();
            this.toggleApplianceSelection(appliance);
        }
    }

    onKeyDownAllAppliance(event: KeyboardEvent) {
        if (event.key === 'Enter') {
            event.preventDefault();
            this.toggleAllAppliancesSelection(event);
        }
    }

    toggleRoomDetailed(room: RoomObj) {
        room.isDetailed = !room.isDetailed;
        this.updateDetailedRoom(room.id as string, room.isDetailed);
        this.countDetailedRooms();
        this.profileState.rooms = this.rooms;
        this.profileState.otherRooms = this.otherRooms;
        this.googleAnalyticsService.event(
            room.isDetailed ? 'add_detailed_cleaning' : 'remove_detailed_cleaning',
            'rooms',
            room.roomName
        );
    }

    toggleApplianceSelection(appliance: ApplianceObj, event?: Event) {
        if (event) {
            const target = event.target as HTMLInputElement;
            appliance.isSelected = target.checked;
        } else {
            appliance.isSelected = !appliance.isSelected;
        }
        this.googleAnalyticsService.event(
            appliance.isSelected ? 'appliance_added' : 'appliance_removed',
            'appliances',
            appliance.name
        );
        this.updateAllAppliancesCheckbox();
        this.getPricingQuote();
        this.profileState.appliances = this.selectedAppliances;
    }

    toggleRoomSelection(room: RoomObj): void {
        room.isSelected = !room.isSelected;
        if (!room.isSelected && !this.isDetailedMode) {
            room.isDetailed = false;
        }
        this.updateSelectedRoom(room.id as string, room.isSelected);
        this.checkIsAnyRoomSelected();
        this.getPricingQuote();
        this.profileState.rooms = this.rooms;
        this.profileState.otherRooms = this.otherRooms;
        this.googleAnalyticsService.event(
            room.isSelected ? 'room_selected' : 'room_deselected',
            'rooms',
            room.roomName
        );
    }

    addRoom(roomName: RoomName) {
        this.pricingQuoteService.addRoomType(roomName).subscribe((newRoom) => {
            newRoom.isSelected = true;
            if (this.isDetailedMode) {
                newRoom.isDetailed = true;
            }
            this.updateSelectedRoom(newRoom.id as string, newRoom.isSelected);
            this.rooms.push(newRoom);
            switch (roomName) {
                case this.roomNames.BEDROOM:
                    this.bedroomsObj = this.mapRooms(roomName);
                    break;
                case this.roomNames.BATHROOM:
                    this.fullBathroomsObj = this.mapRooms(roomName);
                    break;
                case this.roomNames.HALF_BATH:
                    this.halfBathroomsObj = this.mapRooms(roomName);
                    break;
            }
            this.checkIsAnyRoomSelected();
            this.getPricingQuote();
            this.profileState.rooms = this.rooms;
            this.profileState.otherRooms = this.otherRooms;
            this.googleAnalyticsService.event('add_room', 'add_room', roomName);
            this.googleAnalyticsService.event('room_selected', 'rooms', roomName);
        });
    }

    updateSelectedRoom(id: string, checked: boolean) {
        const foundInRooms = this.rooms.find((room) => room.id === id);
        if (foundInRooms) {
            foundInRooms.isSelected = checked;
            if (!checked && !this.isDetailedMode) {
                foundInRooms.isDetailed = false;
                this.countDetailedRooms();
            }
            this.profileState.rooms = this.rooms;
            return;
        }
        const foundInOtherRooms = this.otherRooms.find((room) => room.id === id);
        if (foundInOtherRooms) {
            foundInOtherRooms.isSelected = checked;
            if (!checked && !this.isDetailedMode) {
                foundInOtherRooms.isDetailed = false;
                this.countDetailedRooms();
            }
            this.profileState.otherRooms = this.otherRooms;
            return;
        }
    }

    updateDetailedRoom(id: string, isDetailed: boolean) {
        const foundInRooms = this.rooms.find((room) => room.id === id);
        if (foundInRooms) {
            foundInRooms.isDetailed = isDetailed;
            this.profileState.rooms = this.rooms;
            return;
        }
        const foundInOtherRooms = this.otherRooms.find((room) => room.id === id);
        if (foundInOtherRooms) {
            foundInOtherRooms.isDetailed = isDetailed;
            this.profileState.otherRooms = this.otherRooms;
            return;
        }
    }

    updateAllAppliancesCheckbox() {
        const allAppliancesCheckbox = document.getElementById(
            'allAppliancesCheckbox'
        ) as HTMLInputElement;
        allAppliancesCheckbox.checked = this.isAllAppliancesSelected();
    }

    getPricingQuote() {
        const selectedRooms = [
            ...this.rooms?.filter((room) => room.isSelected),
            ...this.otherRooms?.filter((room) => room.isSelected),
        ];
        this.selectedAppliances = [
            ...this.appliancesObj
                .filter((appliance) => appliance.isSelected)
                .map((appliance) => ({
                    type: appliance.type,
                    quantity: appliance.quantity,
                })),
        ];
        const { bodyRequest, valid } = this.storeDataService.getProfileDataForQuote(
            selectedRooms,
            this.selectedAppliances
        );

        if (valid) {
            this.pricingQuoteService.getPricingQuote(bodyRequest).subscribe((price) => {
                this.price = price;
                this.setPriceByFrequency();
            });
        }
    }

    onFrequencySelected(value: number | string) {
        this.selectedFrequencyValue = value as string;
        if (value !== this.cleaningFrequency.ONE_TIME) {
            this.profileState.cleaningOccasion = null;
        }
        this.frequencyTouched = true;
        this.googleAnalyticsService.event('frequency_changed', 'frequency', value as string);
        if (this.price) {
            this.setPriceByFrequency();
        }
        this.handleNavigation();
        this.profileState.cleaningFrequency = this.selectedFrequencyValue;
    }
    onOccasionSelected(value: number | string) {
        this.selectedOccasionValue = value as string;
        this.profileState.cleaningOccasion = this.selectedOccasionValue;
        this.googleAnalyticsService.event(
            'occasion_checked',
            'occasion',
            this.selectedOccasionValue
        );
        this.handleNavigation();
    }

    setPriceByFrequency() {
        this.isOneTimeClean = false;
        switch (this.selectedFrequencyValue) {
            case CLEANING_FREQUENCY.ONE_TIME:
                this.isOneTimeClean = true;
                this.firstTimePrice = Math.ceil(this.price.oneTimeStandardClean as number);
                break;
            case CLEANING_FREQUENCY.WEEKLY:
                this.recurringPrice = Math.ceil(this.price.standardCleanWeekly as number);
                this.firstTimePrice = Math.ceil(this.price.firstCleanWeekly as number);
                break;
            case CLEANING_FREQUENCY.BI_WEEKLY:
                this.recurringPrice = Math.ceil(this.price.standardCleanBiWeekly as number);
                this.firstTimePrice = Math.ceil(this.price.firstCleanBiWeekly as number);
                break;
            case CLEANING_FREQUENCY.MONTHLY:
                this.recurringPrice = Math.ceil(this.price.standardCleanMonthly as number);
                this.firstTimePrice = Math.ceil(this.price.firstCleanMonthly as number);
                break;
        }
        if (this.isDetailedMode) {
            this.firstTimePrice = Math.ceil(this.price.oneTimeDetailedClean as number);
        }
        this.profileState.firstCleanPrice = this.firstTimePrice?.toString();
        this.profileState.recurringCleanPrice = this.recurringPrice?.toString();
    }

    handleNavigation() {
        if (
            this.isAnyRoomSelected &&
            ((this.selectedFrequencyValue === this.cleaningFrequency.ONE_TIME &&
                this.selectedOccasionValue) ||
                this.selectedFrequencyValue !== this.cleaningFrequency.ONE_TIME)
        ) {
            this.isNextButtonDisabled = false;
            this.isNextButtonActive = true;
            this.navigationService.setNextButtonDisabled(this.isNextButtonDisabled);
            this.navigationService.setNextButtonActive(this.isNextButtonActive);
        } else {
            this.isNextButtonDisabled = true;
            this.navigationService.setNextButtonDisabled(this.isNextButtonDisabled);
        }
    }

    storeCurrentData(button: string) {
        if (this.checkProfileStateInterval) {
            clearInterval(this.checkProfileStateInterval);
        }
        const isBackButton = button === BUTTON_BACK;
        let url = this.navigationRoutes.nextRoute;
        if (isBackButton) {
            url = this.navigationRoutes.backRoute;
        }
        if (!this.frequencyTouched) {
            this.googleAnalyticsService.event(
                'frequency_changed',
                'frequency',
                this.selectedFrequencyValue as string
            );
        }

        this.storeDataService
            .storeCurrentData(
                {
                    ...this.profileState,
                    url: url,
                    subscriptionType: this.subscriptionType,
                },
                false,
                true
            )
            .subscribe(() => {
                this.navigationService.setNavigationButtonClicked({
                    button: button,
                    allowNavigation: true,
                });
            });
    }

    override ngOnDestroy(): void {
        if (this.dialogSubscription) {
            this.dialogSubscription.unsubscribe();
        }
        if (this.checkProfileStateInterval) {
            clearInterval(this.checkProfileStateInterval);
        }
        super.ngOnDestroy();
    }
}
