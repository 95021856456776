<ng-container *ngIf="progressStatus && !progressStatus.isBookingCompleted">
    <div class="navigation" *ngIf="isPrevButtonShown || isNextButtonShown">
        <button
            gaEvent="navigation_back"
            gaCategory="navigation"
            gaLabel="Back"
            title="Back"
            [ngClass]="{'hidden': !isPrevButtonShown }"
            class="btn btn-back"
            (click)="onBackClick()">
            <span class="chevron-icon chevron-back"></span>
            <span class="btn-label">Back</span>
        </button>
        <button
            gaEvent="navigation_next"
            gaCategory="navigation"
            gaLabel="Next"
            class="btn btn-next active"
            (click)="onNextClick()"
            [ngClass]="{'active': isNextButtonActive, 'hidden': !isNextButtonShown }"
            [disabled]="isNextButtonDisabled">
            {{nextButtonLabel}}
        </button>
    </div>
</ng-container>
