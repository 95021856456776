<div class="expandable-list">
    <ul class="block-list">
        <li *ngFor="let item of alwaysVisibleItems">{{ item }}</li>
    </ul>
    <ul *ngIf="isExpandable" class="block-list" [@expandCollapse]="isExpanded ? 'expanded' : 'collapsed'">
        <li *ngFor="let item of expandedItems">{{ item }}</li>
    </ul>
    <div class="link-more" *ngIf="isExpandable">
        <a tabindex="0" (keydown)="handleKeyDown($event)" (click)="toggleExpansion($event)">
            {{ isExpanded ? "Show less" : "Show more" }}
        </a>
    </div>
</div>
