import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { AppRoutingModule } from './routes/app-routing.module';
import { AppComponent } from './app.component';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxSliderModule } from '@angular-slider/ngx-slider';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { ErrorInterceptor } from './common/services/interceptor.service';
import { HeaderComponent } from '@components/header/header.component';
import { ProgressBarComponent } from '@components/progress-bar/progress-bar.component';
import { NavigationComponent } from '@components/navigation/navigation.component';
import { StepperInputComponent } from '@components/stepper-input/stepper-input.component';
import { ProgressBarService } from '@components/progress-bar/progress-bar.service';
import { NavigationService } from '@components/navigation/navigation.service';
import { BaseComponent } from '@components/base-component';

import { YourCleanComponent } from '@pages/your-clean/your-clean.component';
import { QuoteComponent } from '@pages/quote/quote.component';
import { SummaryComponent } from '@pages/summary/summary.component';
import { SpecialAttentionComponent } from '@pages/special-attention/special-attention.component';
import { ManagerRequestComponent } from '@pages/manager-request/manager-request.component';
import { PageNotFoundComponent } from '@pages/page-not-found/page-not-found.component';

import { PickerComponent } from '@components/picker/picker.component';
import { MultiplePickerComponent } from '@components/multiple-picker/multiple-picker.component';
import { SliderComponent } from '@components/slider/slider.component';

import { SpinnerComponent } from '@components/spinner/spinner.component';
import { ScrollerComponent } from '@components/scroller/scroller.component';
import { DetailedDialogComponent } from '@components/detailed-dialog/detailed-dialog.component';
import { ContactDialogComponent } from '@components/contact-dialog/contact-dialog.component';
import { NgxGoogleAnalyticsModule, NgxGoogleAnalyticsRouterModule } from 'ngx-google-analytics';
import { environment } from 'src/environments/environment';
import { ExpandableListComponent } from '@components/expandable-list/expandable-list.component';

@NgModule({
    declarations: [
        AppComponent,
        HeaderComponent,
        ProgressBarComponent,
        NavigationComponent,
        StepperInputComponent,
        BaseComponent,
        YourCleanComponent,
        PickerComponent,
        MultiplePickerComponent,
        SliderComponent,
        QuoteComponent,
        SummaryComponent,
        SpinnerComponent,
        ScrollerComponent,
        SpecialAttentionComponent,
        ManagerRequestComponent,
        PageNotFoundComponent,
        DetailedDialogComponent,
        ContactDialogComponent,
        ExpandableListComponent,
    ],
    bootstrap: [AppComponent],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        NgxGoogleAnalyticsModule.forRoot(environment.gaMeasurementId),
        NgxGoogleAnalyticsRouterModule,
        AppRoutingModule,
        FormsModule,
        NgxSliderModule,
        ReactiveFormsModule,
    ],
    providers: [
        {
            provide: HTTP_INTERCEPTORS,
            useClass: ErrorInterceptor,
            multi: true,
        },
        ProgressBarService,
        NavigationService,
        provideHttpClient(withInterceptorsFromDi()),
    ],
})
export class AppModule {}
