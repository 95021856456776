<div class="picker" role="listbox">
    <div
        *ngFor="let option of _pickerData()"
        class="option"
        role="option"
        [class.active]="option === selectedOption()"
        [attr.aria-selected]="option === selectedOption()"
        (click)="selectOption(option.value)"
        tabindex="0"
        (keydown)="onKeyDown($event, option.value)"
    >
        {{ option.label }}
    </div>
</div>
