import {
    Component,
    Input,
    Output,
    EventEmitter,
    OnInit,
    SimpleChanges,
    OnChanges,
    Renderer2,
} from '@angular/core';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { phoneLengthValidator } from '@common/validators/formValidators';
import { PickerData } from '@common/models/picker-option.model';
import { COMMON_URLS } from '@constants/common-urls.constants';
import { CONTACT_PREFERENCES } from '@constants/contact-preferences.constants';

@Component({
    selector: 'app-contact-dialog',
    templateUrl: './contact-dialog.component.html',
    styleUrls: ['./contact-dialog.component.scss'],
})
export class ContactDialogComponent implements OnInit, OnChanges {
    @Input() isOpen = false;
    @Input() data: any = {};
    @Output() closeDialog = new EventEmitter<any>();
    @Output() updateDialogState = new EventEmitter<any>();
    isClosing = false;
    startY: number = 0;
    endY: number = 0;
    contactData: Array<PickerData> = [
        { label: 'Call', value: CONTACT_PREFERENCES.PHONE },
        { label: 'Text', value: CONTACT_PREFERENCES.TEXT },
    ];
    contactForm: FormGroup = new FormGroup({});
    selectedContactValue = CONTACT_PREFERENCES.PHONE;
    commonUrls = COMMON_URLS;
    isMobile = window.innerWidth <= 576;

    constructor(private formBuilder: FormBuilder, private renderer: Renderer2) {}

    ngOnInit(): void {
        this.buildContactForm();
    }

    buildContactForm(): void {
        this.contactForm = this.formBuilder.group({
            phone: new FormControl(null, [
                Validators.required,
                phoneLengthValidator(10, 10),
                Validators.pattern('^[0-9 ]*$'),
            ]),
            terms: new FormControl(false, Validators.requiredTrue),
        });
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes['data'] && this.data?.phoneNumber) {
            this.contactForm.get('phone')?.setValue(this.data.phoneNumber);
        }
    }

    onContactSelected(value: string | number) {
        this.selectedContactValue = value as string;
    }

    onKeyDownTermsPolicy(event: KeyboardEvent) {
        const target = event.target as HTMLElement;
        if (!(target instanceof HTMLAnchorElement) && event.key === 'Enter') {
            event.preventDefault();
            this.updateDialogState.emit();
            const checkbox = document.getElementById('terms') as HTMLInputElement;
            if (checkbox) {
                checkbox.checked = !checkbox.checked;
                checkbox.dispatchEvent(new Event('change'));
            }
        }
    }

    onKeyDownClose(event: KeyboardEvent) {
        const target = event.target as HTMLElement;
        if (event.key === 'Enter') {
            event.preventDefault();
            this.onClose();
        }
    }

    onTouchStart(event: TouchEvent): void {
        this.startY = event.touches[0].clientY;
        this.disableBodyScroll();
    }

    onTouchMove(event: TouchEvent): void {
        this.endY = event.touches[0].clientY;
    }

    onTouchEnd(): void {
        const swipeDistance = this.endY - this.startY;
        const swipeThreshold = 40;
        if (swipeDistance > swipeThreshold) {
            this.onClose();
        }
    }

    onClose(): void {
        this.enableBodyScroll();
        this.isClosing = true;
        setTimeout(() => {
            this.isOpen = false;
            this.isClosing = false;
            this.closeDialog.emit({ result: null });
        }, 300);
    }

    onSave() {
        this.enableBodyScroll();
        this.isClosing = true;
        setTimeout(() => {
            this.isOpen = false;
            this.isClosing = false;
            this.closeDialog.emit({
                result: {
                    phone: this.contactForm.get('phone')?.value,
                    contactPreference: this.selectedContactValue,
                },
            });
        }, 300);
    }

    disableBodyScroll(): void {
        this.renderer.setStyle(document.body, 'overflow', 'hidden');
    }

    enableBodyScroll(): void {
        this.renderer.removeStyle(document.body, 'overflow');
    }
}
