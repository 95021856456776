import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { phoneLengthValidator, domainValidator } from '@common/validators/formValidators';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { BaseComponent } from '@components/base-component';
import { Profile } from '@common/models/profile.model';
import { BUTTON_BACK } from '@constants/pages-config.constants';
import { StoreDataService } from '@common/services/store-data.service';
import { COMMON_URLS } from '@constants/common-urls.constants';
import { PickerData } from '@common/models/picker-option.model';
import { DaySchedule, WeekdayTitle } from '@common/models/scheduling.model';
import { WEEKDAY_TITLES } from '@constants/weekday-titles.constants';
import { CONTACT_PREFERENCES } from '@constants/contact-preferences.constants';
import { ROOM_NAMES } from '@constants/room-data.constants';
import { ApplianceObj, Room, RoomObj, SelectedAppliance } from '@common/models/rooms.model';
import { ALL_ROOMS_OBJ, APPLIANCES_OBJ } from '@constants/room-data.constants';
import { PricingQuoteService } from '@common/services/pricing-quote.service';
import { Price } from '@common/models/price.model';
import { CLEANING_FREQUENCY } from '@constants/quote-properties.constants';
import { CHECK_PROFILE_STATE_INT_VALUE } from '@constants/common.constants';

@Component({
    selector: 'app-summary',
    templateUrl: './summary.component.html',
    styleUrl: './summary.component.scss',
})
export class SummaryComponent extends BaseComponent implements OnInit, OnDestroy {
    override progressBarStatus = {
        progress: 3,
        completedStep: 2,
    };
    override navigationSubscription!: Subscription;
    override navigationRoutes = {
        nextRoute: this.pagesUrls[this.pages.BOOKING_COMPLETED],
        backRoute: this.pagesUrls[this.pages.QUOTE],
    };
    override isNextButtonDisabled = true;
    override isNextButtonActive = false;
    nextButtonText = 'Let`s get in touch!';
    profileSubscription!: Subscription;
    commonUrls = COMMON_URLS;
    roomNames = ROOM_NAMES;

    firstName!: string;
    lastName!: string;
    email!: string;
    phone!: string;
    petsNumber!: number;
    levelsNumberText!: string;
    levelsNumberMap: { [key: number]: string } = {
        '1': 'One',
        '2': 'Two',
        '3': 'Three',
        '4': 'Four',
    };
    cleaningFrequency = CLEANING_FREQUENCY;
    frequencyTitleMap: { [key: string]: string } = {
        [this.cleaningFrequency.WEEKLY]: 'Weekly',
        [this.cleaningFrequency.BI_WEEKLY]: 'Every other week',
        [this.cleaningFrequency.MONTHLY]: 'Every four weeks',
        [this.cleaningFrequency.ONE_TIME]: 'One-time',
    };

    squareFootage!: string;
    address!: string;
    allRooms!: Room[];
    selectedRoomsObj!: RoomObj[];
    selectedAppliances: SelectedAppliance[] = [];
    selectedAppliancesObj!: ApplianceObj[];

    schedulingTableData: DaySchedule[] = [];

    contactPrefData: Array<PickerData> = [
        { label: 'Phone', value: CONTACT_PREFERENCES.PHONE },
        { label: 'Text', value: CONTACT_PREFERENCES.TEXT },
        { label: 'Email', value: CONTACT_PREFERENCES.EMAIL },
        { label: 'No preference', value: CONTACT_PREFERENCES.NO_PREFERENCE },
    ];
    contactPrefSelectedValues!: string[];
    specialContactPrefValue = CONTACT_PREFERENCES.NO_PREFERENCE;
    regularContactPrefValues = [
        CONTACT_PREFERENCES.PHONE,
        CONTACT_PREFERENCES.TEXT,
        CONTACT_PREFERENCES.EMAIL,
    ];

    scheduling: DaySchedule[] = [
        { title: 'Monday', isSelected: false },
        { title: 'Tuesday', isSelected: false },
        { title: 'Wednesday', isSelected: false },
        { title: 'Thursday', isSelected: false },
        { title: 'Friday', isSelected: false },
    ];
    daysData: Array<PickerData> = [
        { label: 'Any weekday', value: WEEKDAY_TITLES.ANY },
        { label: 'Mon', value: WEEKDAY_TITLES.MONDAY },
        { label: 'Tue', value: WEEKDAY_TITLES.TUESDAY },
        { label: 'Wed', value: WEEKDAY_TITLES.WEDNESDAY },
        { label: 'Thu', value: WEEKDAY_TITLES.THURSDAY },
        { label: 'Fri', value: WEEKDAY_TITLES.FRIDAY },
    ];
    daysSelectedValues!: WeekdayTitle[];
    specialDayValue = WEEKDAY_TITLES.ANY;
    regularDayValues = [
        WEEKDAY_TITLES.MONDAY,
        WEEKDAY_TITLES.TUESDAY,
        WEEKDAY_TITLES.WEDNESDAY,
        WEEKDAY_TITLES.THURSDAY,
        WEEKDAY_TITLES.FRIDAY,
    ];

    isEditContactsMode = false;
    selectedFrequency!: string;
    frequencyTitle!: string;
    price!: Price;
    isOneTimeClean = false;
    oneTimeStandardClean: number | null = null;
    firstTimePrice!: number | null;
    recurringPrice!: number | null;
    isDetailedMode = false;
    contactsForm: FormGroup = new FormGroup({});
    legalForm: FormGroup = new FormGroup({});
    termsAccepted = false;
    marketingConsent = false;
    notesForm: FormGroup = new FormGroup({});
    notes!: string;

    profileState!: Profile;
    checkProfileStateInterval!: ReturnType<typeof setInterval>;

    constructor(
        private storeDataService: StoreDataService,
        private formBuilder: FormBuilder,
        private pricingQuoteService: PricingQuoteService
    ) {
        super();
        this.checkProfileStateInterval =  setInterval(() => {
            this.storeDataService.updateProfileData(this.profileState);
        }, CHECK_PROFILE_STATE_INT_VALUE);
    }

    override ngOnInit(): void {
        super.ngOnInit();
        super.setBrowserBackRoute(this.navigationRoutes.backRoute);
        this.navigationService.setNextButtonActive(this.isNextButtonActive);
        const nextButtonSubscription = this.navigationService
            .getNavigationButtonClicked()
            .subscribe((res) => {
                if (this.isNavigationButtonClicked(res.button) && !res.allowNavigation) {
                    this.storeCurrentData(res.button);
                }
            });

        this.profileSubscription = this.storeDataService
            .profileObserver()
            .subscribe((profile: Profile) => {
                if (profile.profileId && !profile.skipUpdate) this.setupDefaultOptions(profile);
                delete profile.skipUpdate;
            });
        this.addSubscription(this.profileSubscription);
        this.addSubscription(nextButtonSubscription);
    }

    setupDefaultOptions(profile: Profile) {
        this.profileState = JSON.parse(JSON.stringify(profile));
        this.navigationService.setNextButtonLabel(this.nextButtonText);
        this.buildContactsForm();
        this.handleContactsForm();

        if (profile.firstName) {
            this.firstName = profile.firstName;
            this.contactsForm.get('firstName')?.setValue(profile.firstName);
            this.contactsForm.controls['firstName']?.markAsTouched();
        }
        if (profile.lastName) {
            this.lastName = profile.lastName;
            this.contactsForm.get('lastName')?.setValue(profile.lastName);
            this.contactsForm.controls['lastName']?.markAsTouched();
        }
        if (profile.email) {
            this.email = profile.email;
            this.contactsForm.get('email')?.setValue(profile.email);
            this.contactsForm.controls['email']?.markAsTouched();
        }
        if (profile.phone) {
            this.phone = this.phoneFormatter(profile.phone);
            this.contactsForm.get('phone')?.setValue(profile.phone);
            this.contactsForm.controls['phone']?.markAsTouched();
        }
        if (profile.petsNumber) {
            this.petsNumber = profile.petsNumber;
        }
        if (profile.levels) {
            this.levelsNumberText = this.levelsNumberMap[profile.levels];
        }
        if (profile.square) {
            this.squareFootage = profile.square.toLocaleString('en-US');
        }
        if (profile.contactPreference) {
            this.contactPrefSelectedValues = profile.contactPreference as string[];
        }
        if (profile.cleaningFrequency) {
            this.selectedFrequency = profile.cleaningFrequency;
        }
        if (profile.appliances?.length) {
            this.selectedAppliances = profile.appliances;
            this.selectedAppliancesObj = profile.appliances.map((appliance: any) => ({
                ...APPLIANCES_OBJ[appliance.type],
                type: appliance.type,
            }));
        }
        if (profile.rooms?.length && profile.otherRooms?.length) {
            const roomOrder: string[] = [
                this.roomNames.BEDROOM,
                this.roomNames.BATHROOM,
                this.roomNames.HALF_BATH,
            ];
            const sortedRooms = profile.rooms.sort((a, b) => {
                const indexA = roomOrder.indexOf(a.roomName.toLowerCase());
                const indexB = roomOrder.indexOf(b.roomName.toLowerCase());
                return (
                    (indexA !== -1 ? indexA : roomOrder.length) -
                    (indexB !== -1 ? indexB : roomOrder.length)
                );
            });
            this.allRooms = [...sortedRooms, ...profile.otherRooms];
            this.checkIsDetailedMode();
            this.selectedRoomsObj = this.mapSelectedRooms();
            this.getPricingQuote();
        }
        if (profile.termsAccepted) {
            this.termsAccepted = true;
        }
        if (profile.marketingConsent) {
            this.marketingConsent = true;
        }
        if (profile.concerns) {
            this.notes = profile.concerns;
        }
        const { address, postalCode, city, state } = this.storeDataService.profileData;
        const suite =
            this.storeDataService.profileData.suite &&
            this.storeDataService.profileData.suite !== 'null'
                ? ', ' + this.storeDataService.profileData.suite
                : '';
        this.address = `${address}${suite}, ${city}, ${state} ${postalCode}`;
        if (profile.scheduling?.length) {
            this.scheduling = profile.scheduling;
            const isEveryDaySelected = profile.scheduling.every((day) => day.isSelected);
            if (isEveryDaySelected) {
                this.daysSelectedValues = [WEEKDAY_TITLES.ANY];
            } else {
                this.daysSelectedValues = profile.scheduling
                    .filter((day) => day.isSelected)
                    .map((day) => day.title);
            }
        };
        this.buildNotesForm();
        this.handleNotesForm();
        this.buildLegalForm();
        this.handleLegalForm();
        this.handleNavigation();
    }

    checkIsDetailedMode() {
        this.isDetailedMode = this.allRooms.every((room) => room.isDetailed);
    }

    setPriceByFrequency() {
        this.isOneTimeClean = false;
        if (this.selectedFrequency) {
            this.frequencyTitle = this.frequencyTitleMap[this.selectedFrequency];
            switch (this.selectedFrequency) {
                case CLEANING_FREQUENCY.ONE_TIME:
                    this.isOneTimeClean = true;
                    this.firstTimePrice = Math.ceil(this.price.oneTimeStandardClean as number);
                    break;
                case CLEANING_FREQUENCY.WEEKLY:
                    this.recurringPrice = Math.ceil(this.price.standardCleanWeekly as number);
                    this.firstTimePrice = Math.ceil(this.price.firstCleanWeekly as number);
                    break;
                case CLEANING_FREQUENCY.BI_WEEKLY:
                    this.recurringPrice = Math.ceil(this.price.standardCleanBiWeekly as number);
                    this.firstTimePrice = Math.ceil(this.price.firstCleanBiWeekly as number);
                    break;
                case CLEANING_FREQUENCY.MONTHLY:
                    this.recurringPrice = Math.ceil(this.price.standardCleanMonthly as number);
                    this.firstTimePrice = Math.ceil(this.price.firstCleanMonthly as number);
                    break;
            }
            if (this.isDetailedMode) {
                this.firstTimePrice = Math.ceil(this.price.oneTimeDetailedClean as number);
            }
            this.profileState.firstCleanPrice = this.firstTimePrice?.toString();
            this.profileState.recurringCleanPrice = this.recurringPrice?.toString();
        }
    }

    mapSelectedRooms() {
        return this.allRooms
            .filter((room) => ALL_ROOMS_OBJ.hasOwnProperty(room.roomName) && room.isSelected)
            .map((otherRoom) => ({
                ...ALL_ROOMS_OBJ[otherRoom.roomName],
                isSelected: otherRoom.isSelected,
                isDetailed: otherRoom.isDetailed,
                id: otherRoom.id,
            }));
    }

    getPricingQuote() {
        let selectedRooms = this.allRooms.filter((room) => room.isSelected);
        const { bodyRequest, valid } = this.storeDataService.getProfileDataForQuote(
            selectedRooms,
            this.selectedAppliances
        );

        if (valid) {
            this.pricingQuoteService.getPricingQuote(bodyRequest).subscribe((price) => {
                this.price = price;
                this.setPriceByFrequency();
            });
        }
    }

    buildContactsForm(): void {
        this.contactsForm = this.formBuilder.group({
            firstName: new FormControl(null, [
                Validators.required,
                Validators.minLength(1),
                Validators.maxLength(35),
                Validators.pattern("^(?!\\s*$)[a-zA-Z-'`‘’()\u2018\u2019 ]*$"),
            ]),
            lastName: new FormControl(null, [
                Validators.required,
                Validators.minLength(1),
                Validators.maxLength(35),
                Validators.pattern("^(?!\\s*$)[a-zA-Z-'`‘’()\u2018\u2019 ]*$"),
            ]),
            email: new FormControl(null, [
                Validators.required,
                Validators.minLength(1),
                Validators.maxLength(250),
                domainValidator,
                Validators.pattern(
                    /^[\w]{1,}[\w.+-]{0,}@[\w-]{1,}([.][a-zA-Z]{2,}|[.][\w-]{2,}[.][a-zA-Z]{2,})$/
                ),
            ]),
            phone: new FormControl(null, [
                Validators.required,
                phoneLengthValidator(10, 10),
                Validators.pattern('^[0-9 ]*$'),
            ]),
        });
    }

    handleContactsForm(): void {
        const contactsFormSubscription = this.contactsForm.valueChanges.subscribe(() => {
            this.handleNavigation();
        });
        this.addSubscription(contactsFormSubscription);
    }

    buildNotesForm(): void {
        this.notesForm = this.formBuilder.group({
            notes: new FormControl(this.notes, [
                Validators.maxLength(750),
                Validators.pattern(/^[a-zA-Z0-9\s\r\n\p{Emoji}.,!?:;()]*$/u),
            ]),
        });
    }

    handleNotesForm(): void {
        const notesFormSubscription = this.notesForm.valueChanges.subscribe((value) => {
            this.notes = value.notes;
            if (this.notesForm.valid) {
                this.profileState.concerns = this.notes;
            }
            this.handleNavigation();
        });
        this.addSubscription(notesFormSubscription);
    }

    buildLegalForm() {
        this.legalForm = this.formBuilder.group({
            terms: new FormControl(this.termsAccepted, [Validators.requiredTrue]),
            subscription: new FormControl(this.marketingConsent, [Validators.requiredTrue]),
        });
    }

    handleLegalForm() {
        const legalFormSubscription = this.legalForm.valueChanges.subscribe((formValues) => {
            this.termsAccepted = formValues.terms;
            this.marketingConsent = formValues.subscription;
            this.profileState.termsAccepted = this.termsAccepted;
            this.profileState.marketingConsent = this.marketingConsent;
            this.handleNavigation();
        });
        this.addSubscription(legalFormSubscription);
    }

    onContactsFormSubmit() {
        Object.keys(this.contactsForm.controls).forEach((key) => {
            const control = this.contactsForm.get(key);

            if (control) {
                const value = control.value;

                if (value !== null && value !== undefined) {
                    let trimmedValue = value.trim();
                    if (key === 'phone') {
                        trimmedValue = value.trim().replace(/\s/g, '');
                    }
                    if (trimmedValue.length > 0) {
                        control.setValue(trimmedValue);
                    } else {
                        control.setValue(null);
                    }
                }
            }
        });
        if (this.contactsForm.valid) {
            this.isEditContactsMode = false;
            const contactFormValues = { ...this.contactsForm.value };
            this.storeDataService
                .storeCurrentData({
                    ...contactFormValues,
                })
                .subscribe();
        }
    }

    editContactsModeOn() {
        this.isEditContactsMode = true;
    }

    handleEditKeyDown(event: KeyboardEvent) {
        if (event.key === 'Enter') {
            event.preventDefault();
            this.editContactsModeOn();
        }
    }

    handleQuoteKeyDown(event: KeyboardEvent) {
        if (event.key === 'Enter') {
            event.preventDefault();
            this.navigateToQuotePage();
        }
    }

    navigateToQuotePage() {
        this.storeCurrentData(BUTTON_BACK);
    }

    onContactPrefSelected(event: (string | number)[]) {
        this.contactPrefSelectedValues = event as string[];
        this.profileState.contactPreference = this.contactPrefSelectedValues;
        this.handleNavigation();
    }

    onScheduleSelected(event: (string | number)[]) {
        this.daysSelectedValues = event as WeekdayTitle[];
        if (this.daysSelectedValues?.length) {
            let scheduling;
            if (this.daysSelectedValues[0] === WEEKDAY_TITLES.ANY) {
                scheduling = this.scheduling?.map((day) => ({
                    ...day,
                    isSelected: true,
                }));
            } else {
                scheduling = this.scheduling?.map((day) => ({
                    ...day,
                    isSelected: this.daysSelectedValues.includes(day.title),
                }));
            }
            this.profileState.scheduling = scheduling;
        }
        this.handleNavigation();
    }

    handleNavigation() {
        if (
            this.daysSelectedValues?.length &&
            this.contactPrefSelectedValues?.length &&
            this.legalForm.valid &&
            this.notesForm.valid &&
            this.contactsForm.valid
        ) {
            this.isNextButtonDisabled = false;
            this.isNextButtonActive = true;
            this.navigationService.setNextButtonDisabled(this.isNextButtonDisabled);
            this.navigationService.setNextButtonActive(this.isNextButtonActive);
        } else {
            this.isNextButtonDisabled = true;
            this.navigationService.setNextButtonDisabled(this.isNextButtonDisabled);
        }
    }

    onKeyDownTermsPolicy(event: KeyboardEvent) {
        const target = event.target as HTMLElement;
        if (!(target instanceof HTMLAnchorElement) && event.key === 'Enter') {
            event.preventDefault();
            const checkbox = document.getElementById('terms') as HTMLInputElement;
            if (checkbox) {
                checkbox.checked = !checkbox.checked;
                checkbox.dispatchEvent(new Event('change'));
            }
        }
    }

    onKeyDownConsent(event: KeyboardEvent) {
        const target = event.target as HTMLElement;
        if (!(target instanceof HTMLAnchorElement) && event.key === 'Enter') {
            event.preventDefault();
            const checkbox = document.getElementById('subscirpion') as HTMLInputElement;
            if (checkbox) {
                checkbox.checked = !checkbox.checked;
                checkbox.dispatchEvent(new Event('change'));
            }
        }
    }

    phoneFormatter(phone: string): string {
        return `(${phone.slice(0, 3)}) ${phone.slice(3, 6)}-${phone.slice(6, 10)}`;
    }

    storeCurrentData(button: string) {
        if (this.checkProfileStateInterval) {
            clearInterval(this.checkProfileStateInterval);
        }
        const isBackButton = button === BUTTON_BACK;
        if (!isBackButton) {
            if (this.notes) {
                this.googleAnalyticsService.event('notes_added', 'notes', 'Specific needs');
            }
            if (this.contactPrefSelectedValues?.length) {
                const contactPrefs = this.contactPrefSelectedValues.join(', ');
                this.googleAnalyticsService.event(
                    'contact_preferences_checked',
                    'contact_preference',
                    contactPrefs
                );
            }
            if (this.daysSelectedValues?.length) {
                const dayNames = this.daysSelectedValues.join(', ');
                this.googleAnalyticsService.event('schedule_days_checked', 'schedule', dayNames);
            }
            this.googleAnalyticsService.event('agree_terms', 'terms_policy', 'Terms Of Use');
            this.googleAnalyticsService.event('agree_policy', 'terms_policy', 'Privacy Policy');
            this.storeDataService.storeCurrentData({
                ...this.profileState,
                url: this.navigationRoutes.nextRoute
            }).subscribe(() => {
                this.storeDataService.sendOrder().subscribe(() => {
                    this.navigationService.setNavigationButtonClicked({
                        button: button,
                        allowNavigation: true,
                    });
                });
            });
        } else {
            this.storeDataService.storeCurrentData({
                ...this.profileState,
                url: this.navigationRoutes.backRoute
            }).subscribe(() => {
                this.navigationService.setNavigationButtonClicked({
                    button: button,
                    allowNavigation: true,
                });
            });
        }
    }

    override ngOnDestroy(): void {
        if (this.checkProfileStateInterval) {
            clearInterval(this.checkProfileStateInterval);
        }
        super.ngOnDestroy();
    }
}
