<div class="info-container">
    <div class="info-area">
        <img
            src="/assets/images/placeholder-img.svg"
            class="placeholder-image"
            alt="placeholder image"
        />
        <h1 class="title">We'll Reach Out to You!</h1>
        <h2>Thank you!</h2>
        <p class="text-info">
            Just sit back and relax. Your local Merry Maids will contact you shortly to finalize your
            request! Looking forward to helping you keep your space spotless!
        </p>

    </div>
    <a [href]="localSiteUrl" class="link-back">Back to main site</a>
</div>
