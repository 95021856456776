import { Component, OnInit, OnDestroy, Renderer2 } from '@angular/core';
import { StoreDataService } from '@common/services/store-data.service';
import { BaseComponent } from '@components/base-component';
import { COMMON_URLS } from '@constants/common-urls.constants';


@Component({
    selector: 'app-booking-completed',
    templateUrl: './booking-completed.component.html',
})
export class BookingCompletedComponent  extends BaseComponent implements OnInit, OnDestroy {
    override progressBarStatus = {
        progress: 0,
        completedStep: 0,
        isProgressBarShown: false
    };
    commonUrls = COMMON_URLS;
    localSiteUrl = this.commonUrls.LOCAL_SITE;
    override disableNavigation = true;

    constructor (
        private storeDataService: StoreDataService,
        private renderer: Renderer2,
    ) {
        super()
    }

    override ngOnInit(): void {
        super.ngOnInit();
        this.renderer.addClass(document.body, 'special-page');
        this.localSiteUrl = this.storeDataService.profileData.localSiteUrl || this.localSiteUrl;
        this.navigationService.setNextNavigationShown(false);
        this.navigationService.setPrevNavigationShown(false);
        this.progressBarService.changeProgressBarStatus(this.progressBarStatus);
    }

    override ngOnDestroy(): void {
        super.ngOnDestroy();
        this.renderer.removeClass(document.body, 'your-special-class');
    }
}
