import { Component, inject, Input } from '@angular/core';
import { trigger, state, style, animate, transition } from '@angular/animations';
import { GoogleAnalyticsService } from 'ngx-google-analytics';

@Component({
    selector: 'app-expandable-list',
    templateUrl: './expandable-list.component.html',
    styleUrl: './expandable-list.component.scss',
    animations: [
        trigger('expandCollapse', [
            state(
                'collapsed',
                style({
                    height: '0px',
                    opacity: 0,
                })
            ),
            state(
                'expanded',
                style({
                    height: '*',
                    opacity: 1,
                })
            ),
            transition('collapsed <=> expanded', [animate('300ms ease-in-out')]),
        ]),
    ],
})
export class ExpandableListComponent {
    @Input() items: string[] = [];
    @Input() defaultVisibleCount: number = 3;
    googleAnalyticsService = inject(GoogleAnalyticsService);

    isExpanded: boolean = false;

    get isExpandable(): boolean {
        return this.items.length > this.defaultVisibleCount;
    }

    get alwaysVisibleItems(): string[] {
        return this.items.slice(0, this.defaultVisibleCount);
    }

    get expandedItems(): string[] {
        return this.isExpanded ? this.items.slice(this.defaultVisibleCount, this.items.length) : [];
    }

    toggleExpansion(event: Event): void {
        event.preventDefault();
        this.isExpanded = !this.isExpanded;
        if (this.isExpanded) {
            this.googleAnalyticsService.event('show_more_clicked', 'show_more', 'Show more');
        }
    }

    handleKeyDown(event: KeyboardEvent): void {
        if (event.key === 'Enter') {
            this.toggleExpansion(event);
        }
    }
}
