import { Component, Input, EventEmitter, Output, signal } from '@angular/core';
import { PickerData } from '@common/models/picker-option.model';

@Component({
    selector: 'app-multiple-picker',
    templateUrl: './multiple-picker.component.html',
    styleUrls: ['./multiple-picker.component.scss'],
})
export class MultiplePickerComponent {
    _pickerData = signal<PickerData[]>([]);
    _selectedValues = signal<(string | number)[]>([]);
    @Input() specialValue!: (string | number);
    @Input() regularValues!: (string | number)[];

    @Input()
    set pickerData(data: PickerData[]) {
        this._pickerData.set(data);
    }
    @Input()
    set selectedValues(values: (string | number)[]) {
        if (values) {
            this._selectedValues.set(values);
        }
    }
    @Output() optionSelected = new EventEmitter<(number | string)[]>();

    toggleOption(value: string | number) {
        let currentSelection = this._selectedValues();
        if (value === this.specialValue) {
            this._selectedValues.set([value]);
        } else {
            let updatedSelection = currentSelection?.filter(val => val !== this.specialValue);

            if (updatedSelection?.includes(value)) {
                updatedSelection = updatedSelection?.filter(val => val !== value);
            } else {
                updatedSelection = [...updatedSelection, value];
            }

            if (this.regularValues?.every(day => updatedSelection?.includes(day))) {
                this._selectedValues.set([this.specialValue]);
            } else {
                this._selectedValues.set(updatedSelection);
            }
        }
        this.optionSelected.emit(this._selectedValues());
    }

    onKeyDown(event: KeyboardEvent, value: number | string) {
        if (event.key === 'Enter') {
            this.toggleOption(value);
        }
    }

    isSelected(value: string | number): boolean {
        return this._selectedValues()?.includes(value);
    }
}
